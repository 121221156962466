import { Button, Result } from 'antd'
import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { httpGet } from '../../services/http'
import './style.less'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

export default function Step5Page() {
    const data = useStaticQuery(graphql`
        query {
            s1: file(relativePath: { eq: "graph-feb.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            s2: file(relativePath: { eq: "current-daycount.png" }) {
                childImageSharp {
                    fluid(maxHeight: 25) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            s3: file(relativePath: { eq: "day-release.png" }) {
                childImageSharp {
                    fluid(maxHeight: 25) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            s4: file(relativePath: { eq: "cursor-box.png" }) {
                childImageSharp {
                    fluid(maxHeight: 120) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)
    useEffect(() => {
        httpGet('/landing/article/guide-step-5/read')
    }, [])

    return (
        <>
            <div id="progressBarContainer">
                <div
                    id="progressBar"
                    style={{
                        transform: 'scale(0.66,1)',
                    }}
                />
            </div>
            <Result
                icon={
                    <Img
                        className="guide-image"
                        alt="calendar example"
                        imgStyle={{objectFit: 'contain'}}
                        fluid={data.s1.childImageSharp.fluid}
                    />
                }
                title="Get an overview of your day count on the Graph page"
                subTitle={
                    <div style={{ maxWidth: 500, margin: 'auto', textAlign: 'left' }}>
                        Select an exemption period, to see a specific overview of that period, or
                        select “All data” to get a view across all periods. (Default view is current
                        exemption period)
                        <ul>
                            <Img
                                className="guide-image-mini inline-img"
                                alt="calendar example"
                                imgStyle={{objectFit: 'contain'}}
                                fluid={data.s2.childImageSharp.fluid}
                            />
                            <li>
                                The day counter works as a rolling window of six months.
                                <br />
                                It represents the sum of days in DK within the{' '}
                                {
                                    <div
                                        style={{
                                            display: 'inline',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        past
                                    </div>
                                }{' '}
                                six months.
                                <br />
                                Unless the current exemption period-start, is less than six months
                                ago, then the day counter represents the sum of DK-days since
                                period-start. As per the 42-days-rule.
                            </li>
                            <Img
                                className="guide-image-mini inline-img"
                                alt="calendar example"
                                imgStyle={{objectFit: 'contain'}}
                                fluid={data.s3.childImageSharp.fluid}
                            />
                            <li>
                                The number of days until you get a day released, from the rolling
                                six months window, is shown with a day count from Today until
                                release date.
                            </li>
                            <Img
                                className="guide-image-med inline-img"
                                alt="calendar example"
                                imgStyle={{objectFit: 'contain'}}
                                fluid={data.s4.childImageSharp.fluid}
                            />
                            <li>
                                Place the cursor on the graph, to get the “Curser specific
                                info-box”.
                                <br />
                                It will show you:
                                <br />
                                <ul>
                                    <li>the date</li>
                                    <li>day count in exemption period until this specific date</li>
                                    <li>wether the date is within a DK-period</li>
                                    <li>if you have assigned a note</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                }
                extra={[
                    <Link to="/app/">
                        <Button>skip</Button>
                    </Link>,
                    <Link to="/onboarding/step4/">
                        <Button>back</Button>
                    </Link>,
                    <Link to="/onboarding/step6/">
                        <Button type="primary">Next</Button>
                    </Link>,
                ]}
            />
        </>
    )
}
